@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$primary: #4504C0;
$secondary: #FBBC05;
$dark: #25282B;
$semi-white: #D9D9D9;
$grey: #818181;
$light-grey: #D0D8D5;
$light-white: #F4F4F4;
// CUSTOM THEME
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "dark": $dark,
  "semi-white": $semi-white,
  "grey": $grey,
  "light-grey": $light-grey,
  "light-white": $light-white
);


// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
) !default;


// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;

