#landing {
    #header {
        font-family: 'Poppins';
        //background-color: $primary;
        background-image: url("../../images/png/bg_img.png");
        background-repeat: no-repeat;
        //background-position: center;
        min-height: 90vh;

        .logo {
            margin: 1em 2.4em;
            // margin-left:-9%;
            // margin-right: -13%;
        }

        .btn {
            background: #D9D9D9;
            border-radius: 20px;
            color: $primary;
            font-style: normal;
            font-weight: 500;
            font-size: 35px;

        }
    }
}

.main-header {
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    align-items: center;
    letter-spacing: -0.02em;
}

.sub-text {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    align-items: center;
    letter-spacing: -0.02em;
    line-height: 30px;
}

.iphone {
    background: url("../../images/backgrounds/iphone-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.money-habits {
    background: #F4F4F4;
    border-radius: 20px;
    height: 447px;

    @media (min-width: 1px) and (max-width: #{map-get($grid-breakpoints, 'sm') - .02px}) {
        height: fit-content;
    }
}

.metric-card {
    background: #F4F4F4;
    border-radius: 20px;
    font-weight: 600;
    min-height: 156px;
    display: flex;
    align-items: center;
    justify-content: center;
    // max-width: 300px;
}

.grey-bordered {
    border-radius: 30px;
}

.dotted-lines {
    border: 1px dashed #FFFFFF;
    border-radius: 20px;
    padding: 20px;
    min-height: 400px;
    h3 {
        font-weight: 600;
        font-size: 35px;
        line-height: 52px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;

        color: #FFFFFF;
    }

    p {
        font-weight: 400;
        margin-top: 30px;
        font-size: 22px;
        align-items: center;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        line-height: 40px;
    }
}

.faq-btn-div{
    max-width: 300px;
    width: 100%;
}
.who-we-are{
    p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 45px;
        
        color: #818181;
    }
}